
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: this.$t('login'),
    }
  },

  computed: {
    ...mapState('global', ['loginPageSettings']),
    loginHint() {
      const hintKey = this.$route.query?.['login-hint']
      if (hintKey) {
        return this.$t(hintKey)
      }
      return null
    },
  },

  mounted() {
    if (this.$auth.ctx.route.query.code) {
      this.$store.commit('user/loading', true)
    }
  },
}

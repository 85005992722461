
import { useDispatchStoreAction } from '~/mixins/DispatchStoreAction'

export default {
  mixins: [
    useDispatchStoreAction({
      action: 'user/login',
    }),
  ],

  props: {
    light: {
      type: Boolean,
      default: false,
    },

    linkGuestCart: {
      type: Boolean,
      default: false,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    hint: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        email: '',
        password: '',
      },

      error: false,
      hasSubmitted: false,
      hasHintText: false,
    }
  },

  mounted() {
    setTimeout(() => {
      this.hasHintText = !!this.hint
    }, 100)
  },

  methods: {
    async doLogin() {
      this.hasSubmitted = true

      if (!(await this.$refs.observer.validate())) {
        return
      }

      const toast = this.$toast.show(this.$t('process-login'), {
        duration: null,
      })

      try {
        await this.dispatchStoreAction(
          { ...this.form, linkGuestCart: this.linkGuestCart },
          {
            silentOnSuccess: true,
            silentOnError: true,
          }
        )
      } catch (_) {
        this.error = true
      }

      toast.goAway(3000)
    },
  },
}
